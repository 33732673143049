import { useEffect, useState } from "react";
import { Col, Form, Image, Input, Modal, Row, Select, Typography, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { getCookie } from "../../utils/localStorageHandle";
import * as CONSTANTS from "../../utils/constants";
import { FormatVND } from "../../utils/format";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PickWareHouse } from "../../helper";
import { useCookies } from "react-cookie";
import axiosInstance from "../../utils/axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CustomUpload from "../../component/customUpload";
import banking from "../../assets/image/banking.jpg"

export default function Checkout() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [cart, setCart] = useState([]);
    const [province, setProvince] = useState([]);
    const [district, setDistrict] = useState([]);
    const [ward, setWard] = useState([]);

    const [fromAddress, setFromAddress] = useState("");
    const [selectProvince, setSelectProvince] = useState({});
    const [selectDistrict, setSelectDistrict] = useState({});
    const [selectWard, setSelectWard] = useState({});
    const [shipFee, setShipFee] =  useState(0);
    const [totalBill, setTotalBill] =  useState(0);
    const [imgTransfer, setImgTransfer] = useState("");

    const [voucher, setVoucher] = useState({});
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);

    const fetchProvinceByGHN = async () => {
        await axios.get(`${process.env.REACT_APP_API_GHN_URL}/master-data/province`, {
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_GHN_TOKEN,
            }
        })
          .then((res) => {
            const data = res?.data;
            setProvince(data);
          })
          .catch(() => message.error("Error server!"));
    };

    const handleProvinceChange = async (value) => {
        form.setFieldsValue({ district: null });
        const selected = province?.data?.find(item => item?.ProvinceID === value);
        const ProvinceName = selected?.ProvinceName;
        setSelectProvince({ProvinceID: value, ProvinceName: ProvinceName});
        
        await axios.get(`${process.env.REACT_APP_API_GHN_URL}/master-data/district`, {
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_GHN_TOKEN,
            },
            params: {
                province_id: value,
            }
        })
          .then((res) => {
            const data = res?.data;
            setDistrict(data);
          })
          .catch(() => message.error("Error server!"));
    }

    const handleDistrictChange = async (value) => {
        form.setFieldsValue({ ward: null });
        const selected = district?.data?.find(item => item?.DistrictID === value);
        setSelectDistrict({DistrictID: value, DistrictName: selected?.DistrictName});
        await axios.get(`${process.env.REACT_APP_API_GHN_URL}/master-data/ward`, {
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_GHN_TOKEN,
            },
            params: {
                district_id: value,
            }
        })
          .then((res) => {
            const data = res?.data;
            setWard(data);
          })
          .catch(() => message.error("Error server!"));
    }

    const handleWardChange =async (value) => {
        const selected = ward?.data?.find(item => item?.WardCode === value);
        setSelectWard({WardCode: value, WardName: selected?.WardName});
        
        const pick = PickWareHouse(selectProvince?.ProvinceName);
        setFromAddress(pick?.pick_province);
        
        await axios.get(`${process.env.REACT_APP_API_GHN_URL}/v2/shipping-order/available-services?shop_id=5321596&from_district=${pick?.from_district_id}&to_district=${selectDistrict?.DistrictID}`, {    
                headers: {
                    'Content-Type': 'application/json',
                    'Token': process.env.REACT_APP_GHN_TOKEN,
                },
            }).then(async (res) => {
                const data = res?.data;
                await axios.post(`${process.env.REACT_APP_API_GHN_URL}/v2/shipping-order/fee`, 
                {
                    "service_id": data?.data[0]?.service_id,
                    "insurance_value":1000000,
                    "coupon": null,
                    "from_district_id": pick?.from_district_id,
                    "to_district_id": selectDistrict?.DistrictID,
                    "to_ward_code": value,
                    "height":15,
                    "length":15,
                    "weight":1000,
                    "width":15
                },{    
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': process.env.REACT_APP_GHN_TOKEN,
                    },
                })
                .then((res) => {
                    const data = res?.data?.data;
                    setShipFee(data?.service_fee);
                })
                .catch(() => message.error("Error server!"));
            })
            .catch(() => message.error("Error server!"));

    }

    const handleClickVoucher = async () => {
        if(voucher?.voucher_code) {
            await axios.get(`${process.env.REACT_APP_API_URL}/voucher/getByVoucherCode/${voucher?.voucher_code}`)
              .then((res) => {
                const data = res?.data;
                if(data?.status === 1) {
                    message.success(String(data?.message));
                    setVoucher(data?.data);
                } else {
                    message.info(String(data?.message));
                }
              })
              .catch(() => message.error("Error server!"));
        } else {
            message.info("Vui lòng nhập voucher!");
        }
    }

    const handleClickCheckOut = async (value) => {
        let dataProduct = [];
        cart.map((item) => {
            let newProduct = {
                product_id: item.product_id,
                product_name: item.product_name,
                quantity: item.quantity,
                description: item.characteristics.values,
                hash: item.characteristics.characteristics_hash,
                price: item.totalprice,
            };
            dataProduct.push(newProduct);
        });

        if(imgTransfer) {
            const transfer_content = `${cookies?.user?.displayName} chuyen tien`;
            
            const dateMerge = {
                dataProduct: dataProduct,
                fullname: value?.fullname,
                email: value?.email,
                phone: value?.phone,
                from_address: fromAddress,
                to_address: value?.address + ", " + selectWard?.WardName + ", " + selectDistrict?.DistrictName + ", " + selectProvince?.ProvinceName,
                voucher_id: voucher?.voucher_id ? voucher?.voucher_id : null,
                fee_ship: shipFee,
                message: value?.note,
                total_price: voucher?.voucher_id ? totalBill - voucher?.voucher_price + shipFee : totalBill + shipFee,
                transfer_content: transfer_content,
                transfer_image: imgTransfer,
                user_id: cookies?.user?.user_id
            }

            await axiosInstance.post(`/order/create`, dateMerge)
                .then((res) => {
                    const data = res?.data;
                    message.success(String(data?.message));
                    form.resetFields();                
                    localStorage.removeItem(CONSTANTS.cartCookie);
                    navigate("/");
                })
                .catch(() => message.error("Error server!"));
        }else {
            message.info(String("Bạn đang điền thiếu ảnh chứng minh!"))
        }
    }

    useEffect(() => {
        fetchProvinceByGHN();
        setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
    }, []);

    useEffect(() => {
        setTotalBill(cart?.reduce((acc, { totalprice }) => acc + parseInt(totalprice), 0));
    }, [cart]);

    return (
        <div className="max-w-screen-2xl mx-auto my-5">
            <div className="flex">
                <a href="">Trang chủ</a> 
                <p className="px-2">/</p> 
                <a href="/gio-hang">Giỏ hàng</a>
                <p className="px-2">/</p> 
                <a href="/thanh-toan">Trang thanh toán</a>
            </div>

            <Form 
                form={form}
                layout={"vertical"}
                onFinish={handleClickCheckOut}
            >
                <Row>
                    <Col xs={24} xl={24} className='my-5 p-10 bg-white'>
                        <p className="text-xl font-semibold pb-10 text-center">Thông tin mua hàng</p>
                        <div className="grid grid-cols-3 gap-4">
                            <Form.Item
                                label={"Email"}
                                name="email"
                                rules={[{ required: true, message: "Vui lòng nhập email!" }]}
                            >
                                <Input type="email" size="large" placeholder={"nguyen@gmail.com"} />
                            </Form.Item>
                            <Form.Item
                                label={"Họ và tên"}
                                name="fullname"
                                rules={[{ required: true, message: "Vui lòng nhập họ và tên!" }]}
                            >
                                <Input type="text" size="large" placeholder={"Nguyễn Văn A"} />
                            </Form.Item>
                            <Form.Item
                                label={"Số điện thoại"}
                                name="phone"
                                rules={[{ required: true, message: "Vui lòng nhập số điện thoại!" }]}
                            >
                                <Input type="number" size="large" placeholder={"0945125123"} />
                            </Form.Item>
                            <Form.Item
                                label={"Tỉnh"}
                                name="province"
                                rules={[{ required: true, message: "Vui lòng chọn Tỉnh!" }]}
                            >
                                <Select
                                    size="large"
                                    placeholder="Chọn Tỉnh"
                                    optionFilterProp="children"
                                    onChange={handleProvinceChange}
                                    options={province?.data?.map((value) => ({
                                        value: value.ProvinceID,
                                        label: value.ProvinceName,
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Quận/ Huyện"}
                                name="district"
                                rules={[{ required: true, message: "Vui lòng chọn Quận/ Huyện!" }]}
                            >
                                <Select
                                    size="large"
                                    placeholder="Chọn Quận/ Huyện"
                                    optionFilterProp="children"
                                    onChange={handleDistrictChange}
                                    disabled={district?.length === 0}
                                    options={district?.data?.map((value) => ({
                                        value: value.DistrictID,
                                        label: value.DistrictName,
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Phường/ Xã"}
                                name="ward"
                                rules={[{ required: true, message: "Vui lòng chọn Phường/ Xã!" }]}
                            >
                                <Select
                                    size="large"
                                    placeholder="Chọn Phường/ Xã"
                                    optionFilterProp="children"
                                    onChange={handleWardChange}
                                    disabled={ward?.length === 0}
                                    options={ward?.data?.map((value) => ({
                                        value: value.WardCode,
                                        label: value.WardName,
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Địa chỉ chi tiết"}
                                name="address"
                                className="col-span-3"
                                rules={[{ required: true, message: "Vui lòng nhập địa chỉ chi tiết!" }]}
                            >
                                <Input type="text" size="large" placeholder={"Đường A, Khu phố B..."} />
                            </Form.Item>
                            <Form.Item
                                label={"Ghi chú"}
                                className="col-span-3"
                                name="note"
                                rules={[{ required: true, message: "Vui lòng nhập địa chỉ!" }]}
                            >
                                <TextArea rows={4} placeholder="Viết ghi chú ..." />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                
                <Row>
                    <Col xs={24} xl={16}>
                        <Row className="bg-white">
                            <Col xs={24} xl={14} className="p-5 border-r">
                                <p className="font-semibold text-lg text-center pb-10">Thông tin chuyển khoản</p>
                                <p className="flex px-5 pt-3 text-lg">
                                    Nội dung CK:
                                <span className="font-semibold text-xl pl-5">{cookies?.user?.displayName} chuyen tien</span>
                                </p>
                                <p className="px-5 pt-3 text-lg">
                                    Số tiền thanh toán:
                                </p>
                                <p className="font-semibold px-5 pb-3 text-xl text-sky-500">
                                    {FormatVND(voucher?.voucher_id ? totalBill - voucher?.voucher_price + shipFee : totalBill + shipFee)} VND
                                </p>
                                <p className="px-5 text-lg">
                                    Giá trị đơn hàng:
                                </p>
                                <p className="font-semibold px-5 pb-3 text-lg">
                                    {FormatVND(voucher?.voucher_id ? totalBill - voucher?.voucher_price + shipFee : totalBill + shipFee)} VND
                                </p>
                                <div className="px-5 pb-3">
                                    <p className="text-lg">Thêm ảnh chứng minh:</p>
                                    <div className="px-5 flex justify-center py-3">
                                        <p className="flex justify-center">
                                            <CustomUpload type="image" accept=".png, .jpg, .jpeg, .jfif" onChange={(transfer_image)=>setImgTransfer(transfer_image)} value={imgTransfer}/>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} xl={10} className="text-center">
                                <p className="font-semibold text-xl pt-5">Quét mã qua Ứng dụng ngân hàng/</p>
                                <p className="font-semibold text-xl pb-5">Ví điện tử</p>
                                <div className="pb-10 flex justify-center">
                                <div>
                                    <img
                                        src={banking}
                                        className="w-[350px] h-[350px]"
                                    />
                                    <Typography.Paragraph 
                                        className="font-semibold pt-5" 
                                        style={{fontSize: 20}} 
                                        copyable={{
                                            tooltips: [<p className="text-white font-bold">Copy</p>, <p className="text-white font-bold">Copied</p>],
                                        }}
                                    >
                                        BUI THI HUYEN TRANG
                                    </Typography.Paragraph>
                                    <Typography.Paragraph 
                                        className="font-semibold" 
                                        style={{fontSize: 20}} 
                                        copyable={{
                                            tooltips: [<p className="text-white font-bold">Copy</p>, <p className="text-white font-bold">Copied</p>],
                                        }}
                                    >
                                        0923824423
                                    </Typography.Paragraph>
                                </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} xl={8} className="px-2">
                        {cart && (
                            <div>
                                <div className="border ml-2 bg-white pb-5">
                                    <p className="text-xl font-bold py-10 mb-5 text-center border-b bg-blue-50">Đơn hàng</p>
                                    <div className="border-b pb-5">
                                        <div className="px-5 pb-5 flex items-center grid grid-cols-5 gap-2 font-semibold">
                                            <p className="col-span-2">Tên sản phẩm</p>
                                            <p className="text-center">Ảnh</p>
                                            <p className="text-center">Số lượng</p>
                                            <p className="text-center">Thành tiền</p>
                                        </div>
                                        {cart?.map((_,i) => (
                                            <div className="px-5 py-2 flex items-center grid grid-cols-5 gap-2 text-base">
                                                <p className="col-span-2">{_?.product_name?.slice(0, 40)}...</p>
                                                <div className="flex justify-center">
                                                    <Image src={JSON.parse(_?.product_image)?.filter((img) => img.type === 'image')?.[0]?.data} width={60}/>
                                                </div>
                                                <p className="text-center">{_?.quantity}</p>
                                                <p className="text-[#FF424E] font-semibold">{FormatVND(_?.totalprice)}</p>
                                            </div>
                                        ))}

                                        <div className="grid grid-cols-3 px-5 flex items-center pt-10">
                                            <Input className="h-full col-span-2" type="text" value={voucher?.voucher_code} onChange={(e)=>setVoucher({voucher_code: e?.target?.value})} size="large" placeholder={"Mã giảm giá"} />

                                            <p className="w-full py-2 ml-2 bg-blue-500 text-white text-xl font-semibold hover:bg-blue-400 text-center cursor-pointer" onClick={handleClickVoucher}>
                                                Áp dụng
                                            </p>
                                        </div>

                                    </div>
                                    <div className="p-5 border-b text-lg font-semibold">
                                        <div className="flex justify-between">
                                            <p>Tạm tính</p>
                                            <p className="text-[#FF424E]">{FormatVND(totalBill)}</p>
                                        </div>
                                        <div className="flex justify-between py-2">
                                            <p>Phí vận chuyển</p>
                                            <p className={`${shipFee ? "text-red-500" : "text-green-500"}`}>{shipFee? FormatVND(shipFee) : "Free"}</p>
                                        </div>
                                        {voucher?.voucher_price && (
                                            <div className="flex justify-between">
                                                <p>Mã giảm giá</p>
                                                <p className="text-green-500">{FormatVND(voucher?.voucher_price)}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="p-5">
                                        <div className="flex justify-between text-xl font-semibold">
                                            <p>Tổng tiền</p>
                                            <p className="text-[#FF424E]">
                                                {voucher?.voucher_price ? FormatVND(totalBill + shipFee - voucher?.voucher_price) : FormatVND(totalBill + shipFee)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center pt-2">
                                    <Form.Item className="w-full">
                                        <button className="w-full ml-2 py-2 bg-blue-500 text-white text-xl font-semibold hover:bg-blue-400" type="primary" htmlType="submit">
                                            Thanh toán
                                        </button>
                                    </Form.Item>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
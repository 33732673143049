import { useParams } from "react-router-dom"
import ImageGallery from "../../component/imageGallery";
import ProductInfor from "../../section/productInfor";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { message } from "antd";
import parse from 'html-react-parser';

export default function Product() {
    const param = useParams();
    const [product, setProduct] = useState([]);
    const [productImage, setProductImage] = useState([]);
    const [isFile, setIsFile] = useState(false);
    
    const fetchProduct = async () => {
        await axiosInstance.get(`/product/getBySlug/${param?.slug}`)
        .then((res) => {
            const data = res?.data?.data;
            setProduct(data);
            const image = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data;
            const slide = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'slide')?.[0]?.data;
            if(JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data) { setIsFile(true) }
            setProductImage([image, ...slide]);
        })
        .catch(() => message.error("Error server!"));
    };

    useEffect(() => {
        fetchProduct();
    }, [isFile]);

    return (
        <div className="max-w-screen-2xl mx-auto my-5">
            <div className="flex">
                <a href="">Trang chủ</a> 
                <p className="px-2">/</p> 
                <a href="">Danh mục sản phẩm</a>
                <p className="px-2">/</p> 
                <a href="">{product[0]?.product_name}</a>
            </div>

            <div className="grid grid-cols-3 pt-4">
                <div className="bg-white rounded-lg">
                    <ImageGallery product_image={productImage} />
                </div>
                <div className="col-span-2">
                    <ProductInfor product={product[0]} attribute={product?.attribute} isFile={isFile}/>
                    
                    <div className="bg-white p-5 ml-2 rounded-lg mt-2">
                        <p className="text-[16px] font-semibold">Thông tin chi tiết</p>
                        <div className="grid grid-cols-2 pt-2">
                            <div className="text-gray-600">
                                <p className="border-b pb-2 mb-2">Sản phẩm có được bảo hành không?</p>
                                <p className="border-b pb-2 mb-2">Thương hiệu</p>
                                <p className="border-b pb-2 mb-2">Xuất xứ thương hiệu</p>
                                <p className="border-b pb-2 mb-2">Xuất xứ (Made in)</p>
                                <p>Hạn sử dụng</p>
                            </div>
                            <div>
                                <p className="border-b pb-2 mb-2">Không</p>
                                <p className="border-b pb-2 mb-2">Charme</p>
                                <p className="border-b pb-2 mb-2">Việt Nam</p>
                                <p className="border-b pb-2 mb-2">Việt Nam</p>
                                <p>1 năm</p>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <div className="bg-white p-5 rounded-lg mt-2 col-span-3">
                    <p className="text-[16px] font-semibold pb-2">Mô tả sản phẩm</p>
                    {parse(String(product[0]?.product_description))}
                </div>
                    
                <div className="bg-white p-5 rounded-lg mt-2 col-span-3">
                    <p className="text-[16px] font-semibold">Đánh giá</p>
                    <div className="grid grid-cols-2 pt-2">
                        <div className="text-gray-600">
                            <p className="border-b pb-2 mb-2">Sản phẩm có được bảo hành không?</p>
                            <p className="border-b pb-2 mb-2">Thương hiệu</p>
                            <p className="border-b pb-2 mb-2">Xuất xứ thương hiệu</p>
                            <p className="border-b pb-2 mb-2">Xuất xứ (Made in)</p>
                            <p>Hạn sử dụng</p>
                        </div>
                        <div>
                            <p className="border-b pb-2 mb-2">Không</p>
                            <p className="border-b pb-2 mb-2">Charme</p>
                            <p className="border-b pb-2 mb-2">Việt Nam</p>
                            <p className="border-b pb-2 mb-2">Việt Nam</p>
                            <p>1 năm</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { DeleteOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Dropdown, Image, message } from 'antd';
import { useCookies } from "react-cookie";
import { Link, useNavigate } from 'react-router-dom';
import CartMini from './cardMini';

export default function Header() {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    

    const logout = () => {
        removeCookie("user");
        message.success("Đăng xuất thành công!");
        navigate("/dang-nhap");
    }
    
    const items = [
        {
          key: "profile",
          label: (
            <Link to={'/thong-tin-ca-nhan'}>
              <div
                className="flex items-center"
              >
                <span className="cursor-pointer">Thông tin cá nhân</span>
              </div>
            </Link>
          ),
        },
        {
          key: "logout",
          label: (
            <div
              className="flex items-center"
              onClick={() => logout()}
            >
              <span className="cursor-pointer">Thoát</span>
            </div>
          ),
        },
      ];

    return (
        <header>
            <nav className="bg-white border-gray-200 py-2.5 dark:bg-gray-800">
                <div className="flex flex-wrap justify-between items-center max-w-screen-2xl mx-auto pb-2">
                    <a href="/" className="flex items-center">
                        <img src="https://flowbite.com/docs/images/logo.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
                        <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>
                    </a>
                    <div className='w-1/2'>
                        <form>   
                            <div class="relative">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                    </svg>
                                </div>
                                <input type="search" id="default-search" class="block w-full p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tìm kiếm sản phẩm ..." required />
                                <button type="submit" class="text-white absolute end-2.5 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Tìm kiếm</button>
                            </div>
                        </form>
                    </div>
                    {cookies?.user ? (
                        <div className='flex items-center'>
                            <Dropdown placement="bottom" menu={{ items }}>
                                <Image
                                    preview={false}
                                    src={cookies.user?.photos ? cookies.user?.photos : "https://cdn-icons-png.flaticon.com/512/3237/3237472.png"}
                                    width={40}
                                    height={40}
                                />
                            </Dropdown>
                            
                            <Dropdown 
                                trigger={['click']}
                                placement="bottomRight"
                                dropdownRender={()=>(
                                    
                                    <div className='bg-white border shadow-md rounded-lg'>
                                        <CartMini />
                                    </div>
                                )}
                            >
                                <ShoppingCartOutlined className='text-2xl text-[#0b74e5] border-l-2 border-gray-300 ml-2 pl-2 cursor-pointer'/>
                            </Dropdown>
                        </div>
                    ) : (
                        <div className="flex items-center lg:order-2">
                            <a href="/dang-nhap" className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Đăng nhập</a>
                            <a href="/dang-ky" className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">Đăng ký</a>
                            <button data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button>
                        </div>
                    )}
                    
                </div>

                <hr className='my-2'></hr>

                <div className='max-w-screen-2xl mx-auto flex items-center'>
                    <p className='text-[14px] font-semibold text-[#003EA1] py-1'>Cam kết</p>
                    <div className='flex items-center px-5 border-r'>
                        <img src='https://salt.tikicdn.com/ts/upload/96/76/a3/16324a16c76ee4f507d5777608dab831.png' width={20} height={20}/>
                        <p className='pl-2 text-[14px] font-medium text-[#27272A]'>100% hàng thật</p>
                    </div>
                    <div className='flex items-center px-5 border-r'>
                        <img src='https://salt.tikicdn.com/ts/upload/0b/f2/19/c03ae8f46956eca66845fb9aaadeca1e.png' width={20} height={20}/>
                        <p className='pl-2 text-[14px] font-medium text-[#27272A]'>Hoàn 200% nếu hàng giả</p>
                    </div>
                    <div className='flex items-center px-5 border-r'>
                        <img src='https://salt.tikicdn.com/ts/upload/3a/f4/7d/86ca29927e9b360dcec43dccb85d2061.png' width={20} height={20}/>
                        <p className='pl-2 text-[14px] font-medium text-[#27272A]'>30 ngày đổi trả</p>
                    </div>
                    <div className='flex items-center px-5 border-r'>
                        <img src='https://salt.tikicdn.com/ts/upload/87/98/77/fc33e3d472fc4ce4bae8c835784b707a.png' width={20} height={20}/>
                        <p className='pl-2 text-[14px] font-medium text-[#27272A]'>Giao nhanh 2h</p>
                    </div>
                    <div className='flex items-center px-5'>
                        <img src='https://salt.tikicdn.com/ts/upload/6a/81/06/0675ef5512c275a594d5ec1d58c37861.png' width={20} height={20}/>
                        <p className='pl-2 text-[14px] font-medium text-[#27272A]'>Giá siêu rẻ</p>
                    </div>
                </div>
            </nav>
        </header>
    );
}

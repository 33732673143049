import { FormatVND } from "../../utils/format";
import { Rate, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import freeship from "../../assets/image/freeship.png";
import InputGroup from "../../component/inputGroup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { buyProduct } from "../../redux/actions/buyProduct";
import { discountPrice } from "../../helper";
import { useCookies } from "react-cookie";

export default function ProductInfor({product, attribute, isFile}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const [count, setCount] = useState(0);
    const [warehouse, setWareHouse] = useState(0);
    const [select, setSelect] = useState();

    const addToCard = () => {
        if (cookies?.user) {
            if (select == undefined) {
              message.warning("Vui lòng chọn loại sản phẩm bạn muốn mua!");
            } else {
              const product_current = {
                product_id: product?.product_id,
                product_name: product?.product_name,
                product_image: product?.product_image,
                price: product?.product_discount
                  ? discountPrice(
                        product?.product_price,
                        product?.product_discount
                    )
                  : product?.product_price,
                quantity: count,
                characteristics: select,
                totalprice: product.product_discount
                  ? discountPrice(
                        product?.product_price,
                        product?.product_discount
                    ) * count
                  : product?.product_price * count,
              };
              message.success("Thêm sản phẩm vào giỏ hàng thành công!")
              dispatch(buyProduct(product_current));
              navigate("/gio-hang");
            }
          } else {
            message.info("Bạn cần phải đăng nhập để có thể mua hàng!");
          }
    }

    const addToCheckout = () => {
        if (cookies?.user) {
            if (select == undefined) {
              message.warning("Vui lòng chọn loại sản phẩm bạn muốn mua!");
            } else {
              const product_current = {
                product_id: product?.product_id,
                product_name: product?.product_name,
                product_image: product?.product_image,
                price: product?.product_discount
                  ? discountPrice(
                        product?.product_price,
                        product?.product_discount
                    )
                  : product?.product_price,
                quantity: count,
                characteristics: select,
                totalprice: product.product_discount
                  ? discountPrice(
                        product?.product_price,
                        product?.product_discount
                    ) * count
                  : product?.product_price * count,
              };
              message.success("Thêm sản phẩm vào giỏ hàng thành công!")
              dispatch(buyProduct(product_current));
              navigate("/thanh-toan");
            }
          } else {
            message.info("Bạn cần phải đăng nhập để có thể mua hàng!");
          }
    }
    
    return (
        <div className="bg-white p-5 ml-2 rounded-lg">
            <h2 className="text-[20px] font-semibold">{product?.product_name}</h2>
            <div className="flex items-center pb-5">
                <Rate allowHalf defaultValue={4.5} disabled/>
                <p className="border-x px-4 mx-4 text-gray-700"><span className="font-semibold">400</span> Lượt đánh giá</p>
                <p className="text-gray-700"><span className="font-semibold">1,7k</span> đã bán</p>
            </div>
            {product?.product_discount > 0 ? (
                <div className="flex items-center">
                    <p className="text-[#FF424E] font-semibold text-[32px]">{FormatVND(product?.product_price - (product?.product_price * (product?.product_discount/100)))}</p>
                    <div className="flex items-center pl-2">
                        <p className="bg-[#f5f5fa] p-1 rounded-lg text-[12px] font-semibold">-{product?.product_discount}%</p>
                        <p className="text-[#808089] text-[16px] pl-2 line-through">{FormatVND(product?.product_price)}</p>
                    </div>
                </div>
            ) : (
                <p className="text-[#FF424E] font-semibold text-[32px]">{FormatVND(product?.product_price)}</p>
            )}
            <div className="pt-5">
                <p className="text-[16px] font-semibold">Chính sách trả hàng</p>
                <p className="pt-1">Trong vòng 15 ngày đổi trả miễn phí</p>
            </div>
            <div className="pt-5">
                <p className="text-[16px] font-semibold">Vận chuyển</p>
                <div className="flex items-center pt-1">
                    <div className="flex items-center">
                        <img src={freeship} width={30} height={10}/>
                    </div>
                    <p className=" pl-2">Miễn phí vận chuyển</p>
                </div>
            </div>
            <div className="pt-5">
                <p className="text-[16px] font-semibold">Loại</p>
                {isFile ? (
                    <div className="flex pt-2">
                        <p className={`${select ? "border-2 border-sky-700" : "border border-gray-400"} px-2 py-1 mr-2 cursor-pointer`} onClick={()=>{setSelect(attribute?.[0]); setCount(1);}}>
                            Sách bản Online
                        </p>
                    </div>
                ) : (
                    <div className="flex pt-2">
                        {attribute?.map((_,i) => (
                            <p 
                                className={`${select?.characteristics_hash === _?.characteristics_hash ? "border-2 border-sky-700" : "border border-gray-400"} cursor-pointer px-2 py-1 mr-2`} 
                                onClick={()=>{
                                    if(_?.total !== "0"){
                                        setSelect(_); 
                                        setCount(1);
                                    } else {
                                        setCount(0);
                                        window.open(product?.product_link, '_blank');
                                    }
                                }}
                            >
                                {_?.values}
                            </p>
                        ))}
                    </div>
                )}
            </div>
            <div className="pt-5">
                <p className="text-[16px] font-semibold">Số lượng</p>
                {isFile ? (
                    <InputGroup 
                        count={1} 
                        setCount={setCount} 
                        onClickButtonMinus={()=> {
                            message.info("Bạn đang mua sách bản Online!");
                        }}
                        onClickButtonPlus={()=> {
                            message.info("Bạn đang mua sách bản Online!");
                        }}
                    />
                ) : (
                    <InputGroup 
                        count={count} 
                        setCount={setCount} 
                        onClickButtonMinus={()=> {
                            if(count > 1) {
                                setCount(count - 1);
                            }
                        }}
                        onClickButtonPlus={()=> {
                            if(count < select?.total) {
                                setCount(count + 1);
                            }else {
                                message.warning("Vui lòng chọn loại sản phẩm bạn muốn mua!");
                            }
                        }}
                    />
                )}
            </div>
            <div className="pt-5">

                <button 
                    type="submit" 
                    onClick={addToCard}
                    className="w-[200px] text-primary-600 bg-primary-100 border border-primary-600 hover:bg-primary-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium text-[16px] py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                    Thêm vào giỏ
                </button>
                <button 
                    type="submit" 
                    onClick={addToCheckout}
                    className="w-[200px] text-white bg-[#ff424e] hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium text-[16px] ml-2 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                    Mua Ngay
                </button>
            </div>
        </div>
    )
}
import axiosInstance from "../utils/axios";

export const getAll = async (query, setData) => {
    try {
        await axiosInstance.get(`/category/getAll?${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getBySlug = async (query, setData) => {
    try {
        await axiosInstance.get(`/category/getBySlug/${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}
import { Empty, Rate } from "antd";
import { FormatVND } from "../../utils/format";

export default function ItemProduct({
    product, 
    grid, 
    titleSize,
    heightTitle
}) {

    return (
        <div className={`grid grid-cols-${grid} gap-4`}>
            {product?.length > 0 ? product?.map((_, i) => (
                <a href={`/san-pham/${_?.product_slug}`} >
                    <div className="border rounded-lg">
                        <div className="flex justify-center items-center">
                            <img src={JSON.parse(_?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data} className="p-5 w-[160px] h-[160px]"/>
                        </div>
                        <div className="p-2">
                            <h3 className={`line-clamp-2 text-[${titleSize}px] h-${heightTitle}`}>{_?.product_name}</h3>
                            <div className="flex items-center py-1">
                                <Rate style={{fontSize: 16}} allowHalf defaultValue={5} disabled/>
                                <p className="pl-2 ml-2 border-l text-[#808089] text-[14px] font-normal">Đã bán: 123</p>
                            </div>
                            <div className="py-2">
                                {_?.product_discount ? (
                                    <>
                                        <p className="text-[#FF424E] font-semibold text-[16px]">{FormatVND(_?.product_price - (_?.product_price * (_?.product_discount/100)))}</p>
                                        <div className="flex items-center">
                                            <p className="bg-[#f5f5fa] p-1 rounded-lg text-[12px] font-semibold">-{_?.product_discount}%</p>
                                            <p className="text-[#808089] text-[10px] pl-2 line-through">{FormatVND(_?.product_price)}</p>
                                        </div>
                                    </>
                                ) : (
                                    <p className="text-[#FF424E] font-semibold text-[16px]">{FormatVND(_?.product_price)}</p>
                                )}
                            </div>
                            <div className="flex border-t pt-2 mt-2">
                                <div className="flex items-center">
                                    <img src="https://salt.tikicdn.com/ts/tka/a8/31/b6/802e2c99dcce64c67aa2648edb15dd25.png" className="h-5"/>
                                </div>
                                <p className="pl-2">{_?.product_time}</p>
                            </div>
                        </div>
                    </div>
                </a>
            )) : (
                <div className={`col-span-${grid}`}>
                    <Empty />
                </div>
            )}
        </div>
    )
}
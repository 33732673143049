
import ItemCategory from '../../section/itemCategory';
import { useParams } from 'react-router-dom';
import ItemProduct from '../../section/itemProduct';
import {dataCategory, dataProduct} from '../../utils/dataFake'
import { useEffect, useState } from 'react';
import { getBySlug } from '../../api/category';

export default function Category() {
    const param = useParams();
    const [category, setCategory] = useState([]);

    useEffect(()=> {
        getBySlug(param?.slug, setCategory);
    }, [])
    return (
        <div className="max-w-screen-2xl mx-auto my-5">
            <div className='grid grid-cols-5 gap-2'>
                <div>
                    <div className='my-2 p-5 bg-white rounded-xl'>
                        <h2 className='text-[16px] font-semibold border-b'>Bộ lọc tìm kiếm</h2>
                        <div className='py-4'>
                            <h3 className='text-[14px] font-semibold'>Thương hiệu</h3>
                            <div className='grid grid-cols-3 gap-2 pt-1'>
                                <p className='border p-1 rounded-full text-center'>Tundo</p>
                                <p className='border p-1 rounded-full text-center'>Daicat</p>
                                <p className='border p-1 rounded-full text-center'>Tundo</p>
                                <p className='border p-1 rounded-full text-center'>Tundo</p>
                            </div>
                        </div>
                        <div className='py-2'>
                            <h3 className='text-[14px] font-semibold'>Màu sắc</h3>
                            <div className='grid grid-cols-3 gap-2 pt-1'>
                                <p className='border p-1 rounded-full text-center'>Xanh</p>
                                <p className='border p-1 rounded-full text-center'>Đỏ</p>
                                <p className='border p-1 rounded-full text-center'>Tím</p>
                                <p className='border p-1 rounded-full text-center'>Vàng</p>
                            </div>
                        </div>
                        <div className='py-2'>
                            <h3 className='text-[14px] font-semibold'>Giá tiền</h3>
                            <div className='grid grid-cols-2 gap-2 pt-1'>
                                <p className='border p-1 rounded-full text-center'>Thấp - cao</p>
                                <p className='border p-1 rounded-full text-center'>cao - thấp</p>
                                <p className='border p-1 rounded-full text-center'>0đ - 1.000.000đ</p>
                                <p className='border p-1 rounded-full text-center'>1tr - 2tr</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-span-4'>
                    <div className='my-2 p-5 bg-white rounded-xl'>
                        <h1 className='text-[28px] font-semibold'>Danh mục sản phẩm</h1>
                    </div>

                    <div className='my-2 p-5 bg-white rounded-xl'>
                        <h2 className='text-[20px] font-semibold'>Khám phá theo danh mục</h2>
                        <div className='py-5'>
                            <ItemCategory category={category?.data} />
                        </div>
                    </div>
                    
                    {category?.data?.dataChild?.map((_, i) => (
                        <div className='my-2 p-5 bg-white rounded-xl'>
                            <div className='flex justify-between'>
                                <h3 className='font-semibold text-lg'>{_?.category_child_name}</h3>
                                <a href={`/danh-muc-con/${_?.category_child_slug}`}>
                                    <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                                </a>
                            </div>
                            <div className='py-4'>
                                <ItemProduct 
                                    product={_?.product} 
                                    grid={4} 
                                    titleSize={16}
                                    heightTitle={12}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}
import { Table, Pagination, Image} from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../component/SearchProps";
import dayjs from "dayjs";
import { FormatVND } from "../../utils/format";


export default function TableOrder({order, pagination, setPagination}) {
    
    const columns = [
        {
            title: <div>ID</div>,
            key: "order_id",
            dataIndex: "order_id",
            sorter: (a, b) => a.order_id - b.order_id,
            render: (_, record) => <div>{record?.order_id}</div>,
        },
        {
            title: <div>Sản phẩm</div>,
            key: "orderDt",
            width: 400,
            dataIndex: "orderDt",
            render: (_, record) => <div>
                {record?.orderDt?.map((item, i) => {
                    return (
                        <div className={`flex items-center py-2 justify-between ${(i === 0 ? 'border-y' : 'border-b')}`}>
                            <div>
                                <p>{item?.product_name?.slice(0, 40)}...</p>
                                <p>{item?.description}</p>
                                <p>{FormatVND(item?.price)}</p>
                            </div>
                            <p>
                                <Image
                                    src={JSON.parse(item?.product_image)?.filter((img) => img.type === 'image')?.[0]?.data}
                                    width={60}
                                    height={60}
                                />
                            </p>
                        </div>
                    );
                })}
            </div>,
        },
        {
          title: <div>Địa chỉ người nhận</div>,
          key: "to_address",
          dataIndex: "to_address",
          ...SearchProps("to_address"),
          render: (_, record) => <div>{record?.to_address}</div>,
        },
        {
            title: <div>Tổng tiền</div>,
            key: "total_price",
            dataIndex: "total_price",
            sorter: (a, b) => a.total_price - b.total_price,
            render: (_, record) => <div>{FormatVND(record?.total_price)}</div>,
        },
        {
            title: <div>Trạng thái</div>,
            key: "status",
            dataIndex: "status",
            render: (_, record) => <div>
                {record?.status == 1 ? <p className="text-green-600 font-bold">Đã xác nhận</p> : <p className="text-red-600 font-bold">Chưa xác nhận</p>}
            </div>,
        },
        {
            title: <div className={"base-table-cell-label "}>Ngày mua</div>,
            key: "create_at",
            dataIndex: "create_at",
            sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
            render: (_, record) => {
                return (
                <div className={"cursor-pointer text-[14px] font-normal"}>
                    <span className={"!inline-block min-w-[100px]"}>
                    {dayjs(record?.create_at).format("DD/MM/YYYY")}
                    </span>
                </div>
                );
            },
        }
    ];

    return (
        <div className="bg-white p-5">
            <h2 className="text-center font-semibold text-xl">Đơn hàng của tôi</h2>
            <div className="w-full h-full mt-5 pb-20 relative">
                <Table
                    className={"custom-table"}
                    dataSource={order?.data}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    className="flex justify-center absolute inset-x-0 bottom-0"
                    current={pagination.page}
                    total={order?.total}
                    pageSize={pagination.pageSize}
                    showSizeChanger
                    onChange={(p, ps)=> {
                        setPagination({
                            page: p,
                            pageSize: ps
                        })
                    }}
                />
            </div>
        </div>
    )
}
import { Empty } from "antd";
import { useNavigate } from "react-router-dom"


export default function ItemCategory({category}) {
    const navigate = useNavigate();
    
    return (
        <div className="grid grid-cols-6 gap-4">
            {category?.dataChild?.length > 0 ? category?.dataChild?.map((_, i) => (
                <a onClick={()=>navigate(`/danh-muc-con/${_?.category_child_slug}`)} className="cursor-pointer">
                    <div className="">
                        <div className="flex justify-center items-center">
                            <img src={_?.category_child_image} className="p-2 w-[150px] h-[150px] border rounded-full bg-gray-50"/>
                        </div>
                        <div className="py-2">
                            <h3 className="line-clamp-2 text-[16px] font-semibold text-center h-10 text-slate-800">{_?.category_child_name}</h3>
                        </div>
                    </div>
                </a>
            )) : (
                <div className={`col-span-6`}>
                    <Empty />
                </div>
            )}
        </div>
    )
}
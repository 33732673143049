import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as CONSTANTS from "../../utils/constants";
import { getCookie, setCookie } from "../../utils/localStorageHandle";
import { calculateTotalPrice } from "../../../src/helper"
import { Button, Empty, Image, message } from "antd";
import { FormatVND } from "../../utils/format";
import InputGroup from "../../component/inputGroup";
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../redux/actions/buyProduct";

export default function CartMini() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cart, setCart] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);

    const onChangeQuantityHandler = (quantity, productID) => {
        if (quantity > 0) {
            if (quantity <= 0) return;
            var foundIndex = cart.findIndex(
                (element) => element.product_id == productID
            );
            cart[foundIndex].quantity = quantity;
            cart[foundIndex].totalprice =
            cart[foundIndex].quantity * cart[foundIndex].price;
            setCookie(CONSTANTS.cartCookie, JSON.stringify(cart));
            setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
            loadTotalPayment();
        }
    };

    const loadTotalPayment = () => {
      setSubtotal(calculateTotalPrice(cart));
    };

    useEffect(() => {
        setTotalPayment(subtotal);
    }, [subtotal]);

    useEffect(() => {
        setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
        if(cart) {
            loadTotalPayment();
        }
    }, [cart]);

    return (
        <section className="w-[600px]">
            <div className='bg-gray-200 pt-2'>
                <h1 className='text-[18px] text-blue-600 font-bold text-center'>Giỏ hàng</h1>
            </div>

            <div className='border-b border-slate-400 p-5 bg-white'>
                <div className="grid grid-cols-6 text-center text-base font-semibold">
                    <p>STT</p>
                    <p>Tên SP</p>
                    <p>Hình ảnh SP</p>
                    <p>Số lượng</p>
                    <p>Số tiền</p>
                    <p>Thao tác</p>
                </div>
            </div>

            {cart ? (
                <div>
                    <div className='bg-white'>
                        {cart?.map((_,i) => (
                            <div className="py-2 border-b border-slate-200 grid grid-cols-6 text-center text-sm font-semibold flex items-center">
                                <p>{i + 1}</p>
                                <p>{_?.product_name?.slice(0,30)}...</p>
                                <p><Image src={JSON.parse(_?.product_image)?.filter((img) => img.type === 'image')?.[0]?.data} width={100} /></p>
                                <p className="flex justify-center items-center">
                                    <InputGroup 
                                        count={_?.quantity} 
                                        setCount={(e) => {
                                            onChangeQuantityHandler(
                                                e.target.value,
                                                _?.product_id
                                            )
                                        }} 
                                        onClickButtonMinus={()=> {
                                            onChangeQuantityHandler(
                                                _?.quantity - 1,
                                                _?.product_id
                                            )
                                        }}
                                        onClickButtonPlus={() => {
                                            !(_?.quantity >= _?.characteristics.total) &&
                                            onChangeQuantityHandler(
                                                _?.quantity + 1,
                                                _?.product_id
                                            );
                                        }}
                                    />
                                </p>
                                <p className="text-[#FF424E] font-semibold">{FormatVND(_?.totalprice)}</p>
                                <p>
                                    <DeleteOutlined 
                                        className="text-red-500"
                                        onClick={()=> {
                                            dispatch(deleteProduct(_));
                                            setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
                                            message.success("Xóa sản phẩm khỏi giỏ hàng thành công!");
                                        }}              
                                    />
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className='my-1 p-2 bg-white'> 
                        <div className="flex justify-between px-20">
                            <p className="text-lg font-semibold">Tổng tiền tạm tính:</p>
                            <p className="text-[#FF424E] font-semibold text-lg">{FormatVND(subtotal)}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <Empty />
            )}

            <div className='py-2 bg-white text-center text-blue-500 text-lg font-semibold bg-gray-100 cursor-pointer underline' onClick={()=>navigate("/gio-hang")}> 
                Xem giỏ hàng
            </div>
        </section>
    )
}
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ItemProduct from '../../section/itemProduct';
import {dataProduct} from '../../utils/dataFake'
import { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../utils/axios';
import { message } from 'antd';
import { getAll } from '../../api/category';

export default function Home() {
    const [category, setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 12,
    });
  
    const fetchProduct = async () => {
      await axiosInstance.get(`/product/getAll`, {params: pagination})
        .then((res) => {
          const data = res?.data;
          setProduct(data);
        })
        .catch(() => message.error("Error server!"));
    };
    
    useEffect(() => {
        getAll("page=1&pageSize=100", setCategory);
        fetchProduct();
    }, [pagination]);

    return (
        <div className="max-w-screen-2xl mx-auto my-5">
            <div className='my-5 p-5 bg-white rounded-lg'>
                <Slide cssClass='rounded-xl'>
                    <div className="each-slide-effect flex justify-center h-[400px]">
                        <img 
                            src='https://salt.tikicdn.com/cache/w750/ts/tikimsp/f6/91/90/a31b6764924077a3f37cda83c29769d0.jpg.webp' 
                            className='w-full h-full'
                            />
                    </div>
                    <div className="each-slide-effect flex justify-center h-[400px]">
                        <img 
                            src='https://salt.tikicdn.com/cache/w750/ts/tikimsp/d9/6a/3d/3ded0fa479a6ef34e5ecbf619fc1d1c1.png.webp' 
                            className='w-full h-full'
                            />
                    </div>
                    <div className="each-slide-effect flex justify-center h-[400px]">
                        <img 
                            src='https://salt.tikicdn.com/cache/w750/ts/tikimsp/d9/6a/3d/3ded0fa479a6ef34e5ecbf619fc1d1c1.png.webp' 
                            className='w-full h-full'
                            />
                    </div>
                </Slide>
            </div>

            <div className='my-5 p-5 bg-white rounded-xl'>
                <div className='grid grid-cols-10 gap-4'>
                    {category?.data?.map((_,i)=> (
                        <a href={`/danh-muc-san-pham/${_?.category_slug}`}>
                            <div className='flex justify-center'>
                                <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                            </div>
                            <p className='text-center'>{_?.category_name}</p>
                        </a>
                    ))}
                </div>
            </div>

            <div className='my-5 p-5 bg-white rounded-xl'>
                <div className='flex justify-between'>
                    <img src='https://salt.tikicdn.com/ts/upload/f8/77/0b/0923990ed377f50c3796f9e6ce0dddde.png' className='h-12'/>
                    <a href='/danh-muc-san-pham/123'>
                        <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                    </a>
                </div>
                <div className='py-8'>
                    <ItemProduct product={product?.data} grid={6} titleSize={13} heightTitle={12}/>
                </div>
            </div>
        </div>
    )
}
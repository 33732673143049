import * as React from 'react';

export default function Footer() {
    return (
        <footer className='bg-white py-10'>
            <div className='grid grid-cols-5 gap-5 max-w-screen-2xl mx-auto'>
                <div className='flex justify-center'>
                    <div className='leading-7'>
                        <h4 className='text-xl font-semibold pb-2'>Hỗ trợ khách hàng</h4>
                        <a href=''>
                            <p>
                                Hotline: 1900-6035 
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                    </div>
                </div>
                
                <div className='flex justify-center'>
                    <div className='leading-7'>
                        <h4 className='text-xl font-semibold pb-2'>Hỗ trợ khách hàng</h4>
                        <a href=''>
                            <p>
                                Hotline: 1900-6035 
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                    </div>
                </div>
                
                <div className='flex justify-center'>
                    <div className='leading-7'>
                        <h4 className='text-xl font-semibold pb-2'>Hỗ trợ khách hàng</h4>
                        <a href=''>
                            <p>
                                Hotline: 1900-6035 
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                    </div>
                </div>
                
                <div className='flex justify-center'>
                    <div className='leading-7'>
                        <h4 className='text-xl font-semibold pb-2'>Hỗ trợ khách hàng</h4>
                        <a href=''>
                            <p>
                                Hotline: 1900-6035 
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                    </div>
                </div>
                
                <div className='flex justify-center'>
                    <div className='leading-7'>
                        <h4 className='text-xl font-semibold pb-2'>Hỗ trợ khách hàng</h4>
                        <a href=''>
                            <p>
                                Hotline: 1900-6035 
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                        <a href=''>
                            <p>
                                Các câu hỏi thường gặp
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
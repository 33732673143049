import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { Menu, message } from "antd";
import { BookOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { getOrderByUser } from "../../api/order";
import { useCookies } from "react-cookie";
import TableOrder from "../../section/tableOrder";
import MyBook from "../../section/myBook";
import { getByUserId } from "../../api/myBook";

export default function Profile() {
    const [num, setNum] = useState(1);
    const [order, setOrder] = useState([]);
    const [book, setBook] = useState([]);
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 5,
    });
    const [paginationBook, setPaginationBook] = useState({
      page: 1,
      pageSize: 5,
    });
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);

    const items = [
        {
          key: 'order',
          label: 'Đơn hàng của tôi',
          icon: <ShoppingCartOutlined />
        },
        {
          key: 'my-book',
          label: 'Sách của tôi',
          icon: <BookOutlined />
        }
    ];

    useEffect(()=> {
        const query = `page=${pagination?.page}&pageSize=${pagination?.pageSize}`;
        getOrderByUser(cookies?.user?.user_id, query, setOrder);
        
        const queryBook = `page=${paginationBook?.page}&pageSize=${paginationBook?.pageSize}`;
        getByUserId(cookies?.user?.user_id, queryBook, setBook);
    }, [pagination, paginationBook])

    return (
        <div className="max-w-screen-2xl mx-auto my-5">
            <div className="flex">
                <a href="">Trang chủ</a> 
                <p className="px-2">/</p> 
                <a href="">Thông tin cá nhân</a>
            </div>

            <div className="grid grid-cols-5 gap-4 pt-4">
                <div>
                    <Menu
                        className="w-full"
                        onClick={(_)=>{
                            if(_?.key == "order") {
                                setNum(1);
                            } else {
                                setNum(2);
                            }
                        }}
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={items}
                    />
                </div>
                <div className="col-span-4">
                    {order && num === 1 && (
                        <TableOrder order={order} pagination={pagination} setPagination={setPagination} />
                    )}
                    {order && num === 2 && (
                        <MyBook book={book} pagination={paginationBook} setPagination={setPaginationBook} />
                    )}
                </div>
            </div>
        </div>
    )
}
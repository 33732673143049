import React, { useState } from 'react';

const PdfView = ({url}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true); // Đánh dấu rằng iframe đã tải xong
  };

  return (
    <div className='w-full h-screen' style={{ position: 'relative' }}>
      <iframe 
        src={url}
        className='w-full'
        height="100%" 
        style={{ border: 'none' }}
        onLoad={handleLoad}// Gọi hàm khi iframe tải xong
      />
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'black', // Màu gần đen với độ trong suốt
        padding: '15px',
        textAlign: 'center',
        color: 'white', // Màu chữ trắng để nổi bật
        zIndex: 10 // Đảm bảo khối nằm trên iframe
      }}>
        Nghiên cứu khoa học
      </div>
      
    </div>
  );
};

export default PdfView;
import React from 'react';
import { Route, Routes } from "react-router-dom";
import Home from "../page/home";
import Product from "../page/product";
import Category from "../page/category";
import Login from "../page/login";
import Cart from "../page/cart";
import Register from '../page/register';
import LoginAdmin from '../page/admin/login';
import Checkout from '../page/checkout';
import Profile from '../page/profile';
import CategoryChild from '../page/category/detail';
import BookDetail from '../page/book/detail';

export default function User () {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dang-nhap" element={<Login />} />
        <Route path="/dang-nhap-admin" element={<LoginAdmin />} />
        <Route path="/dang-ky" element={<Register />} />
        <Route path="/danh-muc-san-pham/:slug" element={<Category />} />
        <Route path="/danh-muc-con/:slug" element={<CategoryChild />} />
        <Route path="/san-pham/:slug" element={<Product />} />
        <Route path="/gio-hang" element={<Cart />} />
        <Route path="/thanh-toan" element={<Checkout />} />
        <Route path="/thong-tin-ca-nhan" element={<Profile />} />
        <Route path="/sach-cua-toi/:slug" element={<BookDetail />} />
      </Routes>
    );
};
import React from 'react';
import User from '../../route/user.route';
import { StyleProvider } from '@ant-design/cssinjs'
import Header from '../header';
import Footer from '../footer';
import money from '../../assets/icon/profit.png'
import speed from '../../assets/icon/speedometer.png'

export default function DefaultLayoutUser() {
  return (
    <StyleProvider hashPriority="high">
      <div className='bg-[#efefef]'>
        <Header />
          <User />
        <Footer />
        <div className="fixed bottom-4 right-4 bg-blue-500 text-white p-3 rounded-lg shadow-lg hover:bg-blue-600 transition">
          <div className='text-center border-b pb-2 mb-2'>
            <div className='flex justify-center'>
              <img src={money} width={40}/>
            </div>
            <p className='font-semibold pt-1'>Kiếm Tiền</p>
          </div>
          <div className='text-center'>
            <div className='flex justify-center'>
              <img src={speed} width={40}/>
            </div>
            <p className='font-semibold pt-1'>Công Cụ</p>
          </div>
        </div>
      </div>
    </StyleProvider>
  );
};

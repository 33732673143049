import { useEffect, useState } from "react";
import PdfView from "../../component/pdfView";
import { useParams } from "react-router-dom";
import { getBySlug } from "../../api/myBook";
import { useCookies } from "react-cookie";
import { Empty, message } from "antd";

export default function BookDetail() {
    const param = useParams();
    const [book, setBook] = useState([]);
    const [cookies] = useCookies(["user"]);

    useEffect(() => {
        const query = `user_id=${cookies?.user?.user_id}`;
        getBySlug(param?.slug, query, setBook, message);
    }, []);

    return (
        <section className="max-w-screen-2xl mx-auto my-10">
            <div className='my-5 p-5 bg-white'>
                <h1 className='text-[28px] font-semibold'>Sách của tôi/ {param?.slug}</h1>
            </div>
            {book?.data?.length > 0 ? (
                <PdfView url={JSON.parse(book?.data?.[0]?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data}/>
            ) : (<Empty />)}
        </section>
    )
}